import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import projImgT1 from "../assets/img/project-imgT1.png";
import projImgT2 from "../assets/img/project-imgT2.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "QuickVids",
      description: "Video Sharing App",
      imgUrl: projImg1,
      githubUrl: "https://github.com/BrijShah25/quickvids"
    },
    {
      title: "GPTX",
      description: "Modern Interface Webpage",
      imgUrl: projImg2,
      githubUrl: "https://github.com/BrijShah25/moderninterfaceweb-gptx"
    },
    {
      title: "NextGen Ecommerce",
      description: "Futurisitic Products Eccomerce Website",
      imgUrl: projImg3,
      githubUrl: "https://github.com/BrijShah25/NextGenEcommerceWebsite"
    },
    {
      title: "Movie World",
      description: "Movie Streaming",
      imgUrl: projImg4,
      githubUrl: "https://github.com/BrijShah25/movieworld"
    },
    {
      title: "Whatsapp Chatroom",
      description: "Real-time Chatroom",
      imgUrl: projImg5,
      githubUrl: "https://github.com/BrijShah25/WhatsappChatRoom"
    },
    {
      title: "Sarah Smart-Chatbot",
      description: "OpenAI Chatbot",
      imgUrl: projImg6,
      githubUrl: "https://github.com/BrijShah25/SarahSmartChatbot"
    },
  ];

  const projects2 = [
    {
      title: "Stone Paper Scissor game for Beginners",
      imgUrl: projImgT1,
      githubUrl: "https://github.com/BrijShah25/JS-StonePaperScissor"
    },
    {
      title: "Currency Converter for Beginners",
      imgUrl: projImgT1,
      githubUrl: "https://github.com/BrijShah25/JS-CurrencyConverter"
    },
    {
      title: "Stopwatch for Beginners",
      imgUrl: projImgT1,
      githubUrl: "https://github.com/BrijShah25/JS-Stopwatch"
    },
    {
      title: "Number Guessing Game for Beginners",
      imgUrl: projImgT2,
      githubUrl: "https://github.com/BrijShah25/Python-NumberGuessingGame"
    },
    {
      title: "Weather Forecast for Beginners",
      imgUrl: projImgT2,
      githubUrl: "https://github.com/BrijShah25/Python-WeatherForecast"
    },
    {
      title: "Maps for Beginners",
      imgUrl: projImgT2,
      githubUrl: "https://github.com/BrijShah25/Python-Maps"
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p><b>I have developed expertise in React.js, JavaScript, and Python, focusing on creating responsive web applications with an intuitive user experience. I've implemented real-time communication using Socket.IO and developed voice-activated assistants with API integration and speech recognition. Additionally, I’ve built beginner-friendly projects that showcase my skills in web development, game creation, and interactive UI design.</b></p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>I consistently work on new projects every two weeks, allowing me to refine my skills and stay updated with the latest technologies. This regular practice enables me to experiment with different frameworks, tools, and programming concepts. Through this approach, I continuously expand my expertise while delivering creative and functional applications.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
