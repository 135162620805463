import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => {
  return (
    <footer id="contact" className="footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} className="text-center text-md-start">
            <h5>Contact Information</h5>
            <p>Deer Park, VIC 3023</p>
            <p>Phone: (61) 422 916 826</p>
            <p>Email: <a href="mailto:shahbrij25@gmail.com">shahbrij25@gmail.com</a></p><br></br>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <h5>Follow Me</h5>
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/brijshah25/"><img src={navIcon1} alt="LinkedIn" /></a>
              <a href="https://github.com/BrijShah25"><img src={navIcon2} alt="GitHub" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
